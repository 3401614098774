import { useCallback, useEffect, useMemo, useState } from "react";
import {
  IApproveTocPayload,
  TOCDetailType,
  TOCItemsType,
} from "../toc-detail-types";
import moment from "moment";
import { PAC_TYPE, TocStatusEnum } from "../constants/index.constant";
import Button from "components/Button";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useSelector } from "react-redux";
import {
  getTocs,
  setCarePlanData,
  setIsTocDirtyForm,
  setIsTocValueChanged,
  setOriginalCarePlanData,
  setTocEditForm,
  updateAchFacilites,
  updateTocDetailsList,
} from "state/features/toc/toc.slice";
import "./toc-revision.styles.scss";
import Checkbox from "components/checkbox/checkbox.component.tsx";
import { approveTocRevisionAsync } from "state/features/toc/toc.action";
import ErrorIcon from "shared/assets/images/error.png";
import Modal from "components/Modal";
import { DndProvider } from "react-dnd";
import EmptyState from "components/empty-state/empty-state.component";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import SortableList from "components/sortable-list/sortable-list.container";
import { IntakeTypeEnum } from "components/sortable-list/sortable-list.props";
import Icon from "components/Icon";
import { setIsOpenAddServiceModal } from "state/features/common/common.slice";
import { CarePlanData } from "state/types/toc-slice.type";
import { useAppDispatch } from "state/store";
import { CarePlanServices } from "shared/types/enum";

interface TocRevisionProps {
  toc: TOCDetailType;
}

const TOCRevision = (props: TocRevisionProps) => {
  const dispatch = useAppDispatch();
  const { facilities, originalCarePlanData, tocEditFormDirty } =
    useSelector(getTocs);
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [acuteLos, setAcuteLos] = useState<{ value: string; error: string }>({
    value: "",
    error: "",
  });

  const {
    tocDetails,
    pacTypes,
    isTocDetailsLoading,
    isLoadingApproveButton,
    tocEditForm,
    carePlanData,
    allFacilities,
  } = useSelector(getTocs);
  const { toc } = props;
  const {
    version,
    createdAt: createdDate,
    approvedAt,
    isExpanded,
    id,
    tocItems,
    notesFromNavigator,
    notesFromPhysician,
    status,
    anticipatedAcuteLOS,
    facilityId,
  } = toc;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [intakeType, setIntakeType] = useState<IntakeTypeEnum>(
    IntakeTypeEnum.NEW
  );
  const getProviderName = useCallback(
    (providerId: number) => {
      if (allFacilities && allFacilities.length > 0 && providerId !== -1) {
        const facility = allFacilities.find((el) => el.id === providerId);
        return facility ? facility.providerName : "";
      } else {
        return "";
      }
    },
    [allFacilities]
  );

  const isPrefferedFacility = (item: TOCItemsType) => {
    const selectedFacilties =
      item.pacTypeName === CarePlanServices.ACUTE ? facilities : allFacilities;
    return selectedFacilties.find(
      (facility) => item.providerId.toString() === facility.id.toString()
    )?.preferredProvider;
  };

  const isFacilityPriviliged = function (item: TOCItemsType) {
    if (item.pacTypeName === CarePlanServices.ACUTE) {
      const isPriviliged = facilities.find(
        (facility) => item.providerId.toString() === facility.id.toString()
      );
      return isPriviliged && isPriviliged !== undefined ? true : false;
    }

    return false;
  };

  const getTargetLos = useCallback((los: number) => {
    if (los === -1) {
      return "";
    }
    return los.toString();
  }, []);

  const initializeTocItemsForm = useCallback(() => {
    if (toc.tocItems && toc.tocItems.length > 0) {
      if (
        toc.status === TocStatusEnum.PENDING ||
        toc.status === TocStatusEnum.NEW
      ) {
        const originalCarePlanDataItems = toc.tocItems
          .filter(
            (carePlanItem) => carePlanItem.pacTypeName !== PAC_TYPE.HOME_SERVICE
          )
          .map((carePlanItem) => {
            return {
              ...carePlanItem,
              longName: carePlanItem.pacTypeName,
              quantity: carePlanItem.targetLos,
            };
          });
        const isAcutePresent = toc.tocItems.findIndex(
          (carePlanItem) =>
            carePlanItem?.pacTypeName?.toLowerCase() ===
            CarePlanServices.ACUTE.toLowerCase()
        );
        if (intakeType === IntakeTypeEnum.OLD && isAcutePresent === -1) {
          originalCarePlanDataItems.unshift({
            longName: CarePlanServices.ACUTE,
            id: "-1",
            pacTypeName: CarePlanServices.ACUTE,
            providerId: facilityId ? facilityId : -1,
            daysType: "day(s)",
            quantity: tocEditForm.acuteLos.value
              ? parseInt(tocEditForm.acuteLos.value)
              : anticipatedAcuteLOS,
            targetLos: tocEditForm.acuteLos.value
              ? parseInt(tocEditForm.acuteLos.value)
              : anticipatedAcuteLOS,
            admission: "after previous",
            pacTypeId:
              pacTypes.length > 0
                ? (pacTypes.find(
                    (pacType) =>
                      pacType.itemShortName.toLowerCase() ===
                      CarePlanServices.ACUTE.toLowerCase()
                  )?.id as number)
                : -1,
          });
        }
        dispatch(setCarePlanData(originalCarePlanDataItems));
        dispatch(setOriginalCarePlanData(originalCarePlanData));
        const isHomeWithoutServicePresent = toc.tocItems.find(
          (carePlanItem: any) =>
            carePlanItem.pacTypeName === PAC_TYPE.HOME_SERVICE
        );
        dispatch(
          setTocEditForm({
            ...tocEditForm,
            navigatorNotes: notesFromNavigator ? notesFromNavigator : "",
            additionalNotes: "",
            acuteLos: {
              value: acuteLos.value ? acuteLos.value.toString() : "",
              error: "",
            },
            isHomeWithNoServicesEnabled: isHomeWithoutServicePresent
              ? true
              : false,
          })
        );
      }
    }
    if (anticipatedAcuteLOS?.toString()) {
      setAcuteLos({ value: anticipatedAcuteLOS.toString(), error: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tocDetails, acuteLos.value, pacTypes]);

  useEffect(() => {
    if (toc.tocItems && toc.tocItems.length > 0) {
      initializeTocItemsForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acuteLos.value, toc.tocItems]);

  useEffect(() => {
    if (
      (intakeType === IntakeTypeEnum.NEW &&
        carePlanData.length === 0 &&
        !toc.tocItems &&
        pacTypes &&
        pacTypes.length > 0) ||
      (intakeType === IntakeTypeEnum.OLD &&
        status === TocStatusEnum.NEW &&
        !toc.tocItems &&
        carePlanData.length === 0 &&
        pacTypes &&
        pacTypes.length > 0) ||
      (!toc.tocItems &&
        carePlanData.length === 0 &&
        pacTypes &&
        pacTypes.length > 0)
    ) {
      dispatch(
        setCarePlanData([
          {
            longName: CarePlanServices.ACUTE,
            id: "-1",
            pacTypeName: CarePlanServices.ACUTE,
            providerId: toc.facilityId ? toc?.facilityId : -1,
            daysType: "day(s)",
            quantity: acuteLos.value,
            targetLos: acuteLos.value,
            admission: "after previous",
            pacTypeId: pacTypes.find(
              (pacType) =>
                pacType.itemShortName.toLowerCase() ===
                CarePlanServices.ACUTE.toLowerCase()
            )?.id as number,
          },
        ])
      );
    }
  }, [
    dispatch,
    toc.intakeId,
    intakeType,
    pacTypes,
    acuteLos,
    toc.tocItems,
    status,
    carePlanData.length,
  ]);

  useEffect(() => {
    if (
      carePlanData &&
      carePlanData.length > 0 &&
      carePlanData.findIndex(
        (carePlan) => carePlan.longName === CarePlanServices.ACUTE
      ) !== -1 &&
      facilities.length > 0 &&
      allFacilities.length > 0
    ) {
      const providerId = carePlanData.find(
        (carePlanItem) => carePlanItem.longName === CarePlanServices.ACUTE
      )?.providerId;
      if (providerId !== undefined && providerId) {
        const provider = allFacilities.find(
          (facility) => facility.id.toString() === providerId.toString()
        );
        const ifProviderExists = facilities.findIndex(
          (facility) => facility.id.toString() === provider?.id.toString()
        );
        if (ifProviderExists === -1 && provider) {
          const copyOfAchFacilities = [...facilities];
          copyOfAchFacilities.push({ ...provider, isPrivileged: false });
          dispatch(updateAchFacilites(copyOfAchFacilities));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carePlanData, facilities, allFacilities]);

  const handleTocExpand = useCallback(() => {
    const tocId = id;
    const newTocs: TOCDetailType[] = [];
    if (toc.approvedAt) {
      tocDetails.forEach((item: TOCDetailType) => {
        const updatedItem = { ...item };
        if (item.approvedAt) {
          if (item.id === tocId) {
            updatedItem.isExpanded = !item.isExpanded;
          } else {
            updatedItem.isExpanded = false;
          }
        }

        newTocs.push(updatedItem as TOCDetailType);
      });
    } else {
      tocDetails.forEach((item: TOCDetailType) => {
        const updatedItem = { ...item };
        if (!item.approvedAt) {
          if (item.id === tocId) {
            updatedItem.isExpanded = !item.isExpanded;
          }
        }

        newTocs.push(updatedItem as TOCDetailType);
      });
    }

    dispatch(updateTocDetailsList(newTocs));
  }, [dispatch, id, tocDetails, toc.approvedAt]);

  const onClickAddService = () => {
    dispatch(setIsOpenAddServiceModal({ isOpen: true, isEdit: false }));
  };

  const checkIfHomeWithNoServiceExist = useMemo(() => {
    return toc.tocItems.find(
      (carePlanItem: any) => carePlanItem.pacTypeName === PAC_TYPE.HOME_SERVICE
    )
      ? true
      : false;
  }, [tocItems]);

  const isAcuteServiceExist = useMemo(() => {
    return toc.tocItems.find(
      (carePlanItem) =>
        carePlanItem.pacTypeName?.toLowerCase() ===
        CarePlanServices.ACUTE.toLowerCase()
    );
  }, [toc.tocItems]);

  const isFacilityError = useMemo(() => {
    return carePlanData.some(
      (planData) =>
        planData.providerId.toString() == "0" ||
        planData.providerId.toString() == "-1"
    );
  }, [carePlanData]);

  const shouldErrorMessageDisplay = () => {
    if (isFacilityError) {
      return true;
    }
    if (!tocEditForm.isHomeWithNoServicesEnabled && carePlanData.length === 1) {
      return true;
    }

    return false;
  };

  const isIntakeOldOrNew = useCallback(() => {
    if (tocDetails.length === 1 && status === TocStatusEnum.NEW) {
      setIntakeType(IntakeTypeEnum.NEW);
    } else {
      setIntakeType(IntakeTypeEnum.OLD);
    }
  }, [status, tocDetails.length]);

  const preparePayload = () => {
    const hwnPacType = pacTypes.find(
      (el) => el.itemShortName === PAC_TYPE.HOME_SERVICE
    );
    const acutePacType = pacTypes.find(
      (el) => el.itemShortName.toLowerCase() === PAC_TYPE.ACUTE.toLowerCase()
    );
    const acuteService = tocItems.find(
      (item) => item.pacTypeName === PAC_TYPE.ACUTE
    );
    let changedValues = [];
    if (tocEditForm.isHomeWithNoServicesEnabled) {
      changedValues = [
        {
          PACTypeName: PAC_TYPE.ACUTE,
          id: acuteService?.id ?? -1,
          ProviderID: acuteService?.providerId ?? 0,
          TargetLOS: acuteService?.targetLos ?? Number(toc.anticipatedAcuteLOS),
          PACTypeId: acutePacType?.id,
          sortOrder: 0,
        },
        {
          PACTypeName: PAC_TYPE.HOME_SERVICE,
          id: -1,
          ProviderID: 0,
          TargetLOS: 0,
          PACTypeId: hwnPacType?.id ?? -1,
          sortOrder: 1,
        },
      ];
    } else {
      changedValues = carePlanData.map((item: CarePlanData, index: number) => {
        const pacType = pacTypes.find((el) =>
          [
            el.itemLongName.toLowerCase(),
            el.itemShortName.toLowerCase(),
          ].includes(item.longName.toLowerCase())
        );
        return {
          id: !item.id || item.id === "" ? crypto.randomUUID() : item.id,
          PACTypeName: item.longName,
          PACTypeId: pacType?.id ?? -1,
          ProviderID: item?.providerId ? Number(item.providerId) : 0,
          ProviderName: item?.providerName ?? "",
          TargetLOS: item?.quantity ? Number(item.quantity) : 0,
          sortOrder: index,
        };
      });
    }
    return {
      id,
      approved: true,
      isModified: tocEditForm.isTocItemsFormDirty,
      notePhysician: tocEditForm.physicianNotes,
      anticipatedAcuteLOS: acuteLos.value,
      transitionOfCareItems: changedValues,
      primaryCareManagerEmail: toc.primaryCareManagerEmail,
      practiceName: toc.practiceName,
      physicianName: toc.physicianName,
    };
  };

  const approveToc = () => {
    if (isConfirmModalOpen) {
      const payload: IApproveTocPayload = preparePayload();
      dispatch(approveTocRevisionAsync(payload));
      setIsConfirmModalOpen(false);
    }
  };

  useEffect(() => {
    isIntakeOldOrNew();
  }, [isIntakeOldOrNew]);

  const getDisableStatusForHomeWithoutServices = () => {
    if (intakeType === IntakeTypeEnum.OLD) {
      return false;
    }
    return true;
  };

  const onPhysicianNotesChange = (val: string) => {
    dispatch(setIsTocValueChanged(true));
    dispatch(
      setTocEditForm({
        ...tocEditForm,
        physicianNotes: val,
        isTocItemsFormDirty: true,
        isFormEmpty: false,
      })
    );
  };

  const handleHomeWithoutNoServices = () => {
    dispatch(setIsTocValueChanged(true));
    dispatch(
      setTocEditForm({
        ...tocEditForm,
        isHomeWithNoServicesEnabled: !tocEditForm.isHomeWithNoServicesEnabled,
        isTocItemsFormDirty: true,
        isFormEmpty: false,
      })
    );
    dispatch(setIsTocDirtyForm(false));
  };

  const renderTocDetailsInReadMode = () => {
    return (
      <>
        <div className={`toc-approve-info-readonly `}>
          <div className="toc-content">
            <div className="toc-plan">
              <div className="episode-details">
                <div className="toc-heading">
                  <div className="toc-plan-heading">Care Plan</div>
                  <div className="toc-plan-los-container">
                    {!isAcuteServiceExist && (
                      <>
                        <div className="acute-los-heading">Acute LOS</div>
                        <div className="acute-los-heading-sep">-</div>
                        <div className="acute-los-readonly">
                          {acuteLos.value.toString()}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="location-grid-container">
              <div className="location-dropdowns">
                <div className="location-row-header">
                  <div className="location">Services</div>
                  <div className="location-name">
                    <div>Provider</div>
                  </div>
                  <div className="los">LOS/Visits</div>
                </div>
                <div>
                  {toc.tocItems &&
                    toc.tocItems.length > 0 &&
                    toc.tocItems
                      .filter(
                        (careItem) =>
                          careItem.pacTypeName !== PAC_TYPE.HOME_SERVICE
                      )
                      .map((careItem) => {
                        return (
                          <div className="location-row">
                            <div className="location-value">
                              {careItem.pacTypeName}
                            </div>
                            <div className="toc-dropdown-readonly">
                              {getProviderName(careItem.providerId)}
                              {isPrefferedFacility(careItem) && (
                                <span>
                                  <Icon icon="preferred" size={16} />
                                </span>
                              )}
                              {isFacilityPriviliged(careItem) && (
                                <span>
                                  <Icon icon="privileged" size={16} />
                                </span>
                              )}
                            </div>
                            <div className="toc-visits-readonly">
                              {getTargetLos(careItem.targetLos)}
                            </div>
                          </div>
                        );
                      })}

                  <div className="read-only-footer">
                    <div className="preferred-container">
                      <Icon icon="preferred" size={16} />
                      <div className="preferred-text">Preferred</div>
                      <Icon icon="privileged" size={16} />
                      <div className="preferred-text">Privileged</div>
                    </div>
                  </div>
                </div>
              </div>

              {checkIfHomeWithNoServiceExist ? (
                <div className="checkbox-container">
                  <div className="checkboxes" key="Incoming-checkox">
                    <Checkbox
                      name="homeWithoutServices"
                      value="Home w/No services"
                      id="Incoming-checkox"
                      labelClassname="home-with-no-service disabled"
                      onChange={() => {}}
                      isDisabled
                      isChecked={true}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="note-container">
            <div className="external-notes">
              <div className="physician-notes">
                <div className="note-heading">Physician Notes</div>
                <div className="note-textarea-readonly">
                  {!!notesFromPhysician && notesFromPhysician.length
                    ? notesFromPhysician
                    : "-"}
                </div>
              </div>
              <div className="navigator-notes">
                <div className="note-heading">Navigator Notes</div>
                <div className="note-textarea-readonly">
                  {!!notesFromNavigator && notesFromNavigator.length
                    ? notesFromNavigator
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTocDetailsInEditMode = () => {
    return (
      <>
        <div className={`toc-approve-info`}>
          <div className="toc-plan">
            <div className="episode-details">
              <div className="toc-plan-heading">Care Plan</div>
              <div className="toc-add-service-with-options">
                <div className="add-service">
                  <Button
                    className="add-service-button white-button"
                    text="Add Service"
                    onClick={onClickAddService}
                    disabled={tocEditForm.isHomeWithNoServicesEnabled}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="note-container">
            <div className="physician-notes">
              <div className="note-heading">Physician Notes</div>
              <textarea
                className="note-textarea"
                value={tocEditForm.physicianNotes ?? ""}
                onChange={(e: any) => {
                  dispatch(setIsTocDirtyForm(false));
                  onPhysicianNotesChange(e.target.value);
                }}
                maxLength={2000}
                placeholder="Write your notes here for internal use..."
                cols={5}
              />
              {tocEditForm.physicianNotes &&
                tocEditForm.physicianNotes?.length > 0 && (
                  <div className="note-hint">
                    <div className="note-limit">{`${
                      tocEditForm.physicianNotes?.length
                    }/${2000}`}</div>
                  </div>
                )}
            </div>
            <div className="navigator-notes">
              <div className="note-heading">Navigator Notes</div>
              <div className="note-textarea-readonly">
                {!!notesFromNavigator && notesFromNavigator.length
                  ? notesFromNavigator
                  : "-"}
              </div>
            </div>
          </div>
          <div className="location-grid-container">
            <div className="location-dropdowns">
              {isTocDetailsLoading && (
                <EmptyState type={EmptyStateType.LOADING} />
              )}
              {!isTocDetailsLoading && (
                <DndProvider backend={HTML5Backend}>
                  <SortableList toc={toc} intakeType={intakeType} />
                </DndProvider>
              )}
            </div>
            {tocEditFormDirty && shouldErrorMessageDisplay() && (
              <div className="toc-plan-error">
                <span>
                  <img src={ErrorIcon} alt="Error" />
                  {isFacilityError
                    ? "Select a provider for all the services"
                    : "Add at least 1 more service or select Home w/No services"}
                </span>
              </div>
            )}
            <div className="checkbox-container">
              <div className="checkboxes" key="Incoming-checkox">
                <Checkbox
                  name="homeWithoutServices"
                  value="Home w/No services"
                  id="Incoming-checkox"
                  onChange={handleHomeWithoutNoServices}
                  isChecked={tocEditForm.isHomeWithNoServicesEnabled}
                  isDisabled={getDisableStatusForHomeWithoutServices()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="last-row-with-approve-button">
          <div className="cta-container">
            <Button
              text="Approve"
              className={`green-button submit-button `}
              buttonWithLoader={true}
              isLoading={isLoadingApproveButton}
              onClick={() => {
                dispatch(setIsTocDirtyForm(true));
                if (!shouldErrorMessageDisplay()) {
                  setIsConfirmModalOpen(true);
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal isOpen={isConfirmModalOpen} className="center">
        <div className="logout-container">
          <div className="header">Approve TOC</div>
          <div className="content">
            Are you sure you want to approve this TOC?
          </div>
          <div className="button-section">
            <Button
              text="Cancel"
              className="white-button cancel-button"
              onClick={() => {
                setIsConfirmModalOpen(false);
              }}
            />
            <Button
              text="Confirm"
              className="green-button reload-button"
              onClick={() => {
                approveToc();
              }}
            />
          </div>
        </div>
      </Modal>
      <div
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        className={`toc-row ${isRowHovered ? "hovered" : ""} ${
          isExpanded ? "opened" : ""
        }`}
      >
        <div className="toc-cell">
          {`Care Plan ${version}`}
        </div>
        <div className="toc-cell">
          {createdDate
            ? moment(createdDate).format("MM/DD/YYYY : hh:mm A")
            : "-"}
        </div>
        <div
          className={`toc-cell ${
            status === TocStatusEnum.PENDING ? "pending-status" : ""
          }`}
        >
          {status}
        </div>
        <div className="toc-cell">
          {approvedAt ? moment(approvedAt).format("MM/DD/YYYY : hh:mm A") : "-"}
        </div>
        <div
          className={`toc-cell icons ${
            isRowHovered || isExpanded ? "visible" : "hidden"
          }`}
        >
          {isExpanded ? (
            <Button
              icon="up-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          ) : (
            <Button
              icon="down-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          )}
        </div>
      </div>
      {isExpanded ? (
        <>
          {status === TocStatusEnum.PENDING
            ? renderTocDetailsInEditMode()
            : renderTocDetailsInReadMode()}
        </>
      ) : null}
    </>
  );
};

export default TOCRevision;

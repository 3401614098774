import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPrivate } from "shared/api/axios-wrapper.service";
import { getAgeFromDate, isEmptyString } from "shared/methods/utilityFunctions";
import { setTocSuggestions } from "./toc.slice";
import { getConfig } from "../../../config/config";
import {
  IApproveTocPayload,
  TOCDetailType,
  TOCItemsType,
} from "pages/toc-detail/toc-detail-types";
import { IEpisodePatientDetails, IFacility } from "state/types/toc-slice.type";
import moment from "moment";
import { TocStatusEnum } from "pages/toc-detail/constants/index.constant";

export const fetchTransitionOfCareItemsAsync = createAsyncThunk(
  "toc/fetchTransitionOfCareItemsAsync",
  async (requestPayload: any, { dispatch, signal }) => {
    const response = await axiosPrivate.post(
      `${getConfig().efusionBase}/api/v2/ExtTransitionOfCare/getbyprovider`,
      requestPayload,
      { signal }
    );
    if (response.status === 200) {
      if (
        (response.data.Count.Pending > 0 || response.data.Count.Approved > 0) &&
        !isEmptyString(requestPayload.patientNameFilter) &&
        requestPayload.patientNameFilter.length >= 2
      ) {
        const pendingList = response.data.ToCList.Pending.filter((item: any) =>
          item.PatientName.toLowerCase().startsWith(
            requestPayload.patientNameFilter
          )
        );
        const approvedList = response.data.ToCList.Approved.filter(
          (item: any) =>
            item.PatientName.toLowerCase().startsWith(
              requestPayload.patientNameFilter
            )
        );
        if (pendingList.length > 0) {
          dispatch(
            setTocSuggestions(
              pendingList[0].PatientName.toLowerCase().split(" ")[0]
            )
          );
        } else if (approvedList.length > 0) {
          dispatch(
            setTocSuggestions(
              approvedList[0].PatientName.toLowerCase().split(" ")[0]
            )
          );
        }
      } else {
        dispatch(setTocSuggestions(""));
      }
    }
    return response.data;
  }
);

export const fetchTransitionOfCareDetailsAsync = createAsyncThunk(
  "toc/fetchTransitionOfCareDetailsAsync",
  async (requestPayload: { intakeId: string }) => {
    const response = await axiosPrivate.get(
      `${getConfig().taskManagementBase}/api/v1/physician/toc/${
        requestPayload.intakeId
      }`
    );
    if (response?.status === 200) {
      const versions = response?.data?.data;
      if (versions && versions.length > 0) {
        const versionsMapped = versions.map((v: any) => {
          return {
            anticipatedAcuteLOS: v.anticipatedAcuteLOS,
            approvedAt: v?.reviewedAt ? `${v?.reviewedAt}Z` : null,
            approvedBy: v.approvedBy,
            primaryCareManagerEmail: v?.primaryCareManagerEmail ?? "",
            careManagerName: v?.primaryCareManagerName ?? "",
            clientId: v?.clientId,
            createdAt: v?.createdAt ? `${v.createdAt}Z` : null,
            createdBy: v?.createdBy ?? "",
            id: v.id,
            intakeId: v.intakeId,
            isCustomised: v.isCustomised,
            isExpanded: v.status === TocStatusEnum.PENDING,
            isModified: v.isModified,
            notesFromNavigator: v.noteCareManager,
            notesFromPhysician: v.notePhysician,
            originalStatus: v.originalStatus,
            physicianName: v.physicianName ?? "",
            practiceName: v?.practiceName ?? "",
            reason: v?.reason ?? "",
            reasonId: v?.reasonId ?? "",
            status: v?.status ?? "",
            statusId: v?.statusId,
            updatedAt: v?.updatedAt ? `${v.updatedAt}Z` : null,
            updatedBy: v?.updatedBy ?? "",
            version: v.version,
            facilityId: v.facilityId,
            surgeonId: v?.surgeonId,
            tocItems:
              v?.transitionOfCareItems?.map((careItem: any) => {
                return {
                  ...careItem,

                  pacTypeId: careItem.pacTypeID,
                } as TOCItemsType;
              }) ?? [],
          } as TOCDetailType;
        });

        return versionsMapped as Array<TOCDetailType>;
      }
    }
    return response.data;
  }
);

export const getAllTocFacilitiesAsync = createAsyncThunk(
  "toc/getAllTocFacilitiesAsync",
  async (intakeId: string) => {
    const response = await axiosPrivate.get(
      `${
        getConfig().efusionBase
      }/api/v2/ExtProvider/getallByIntake/${intakeId}/false/true/false/false/true`,
      { params: {} }
    );
    return response.data.map(
      (el: any) =>
        ({
          id: el.ID,
          firstName: el.FirstName,
          lastName: el.LastName,
          providerName: el.ProviderName,
          providerPhone: el.ProviderPhone,
          preferredProvider: el.PreferredProvider,
          providerAddress1: el.ProviderAddress1,
          providerCity: el.ProviderCity,
          providerState: el.ProviderState,
          providerZip: el.ProviderZip,
          distance: "",
        } as IFacility)
    );
  }
);

export const approveTocRevisionAsync = createAsyncThunk(
  "toc/approveTocRevision",
  async (requestPayload: IApproveTocPayload) => {
    const response = await axiosPrivate.put(
      `${getConfig().taskManagementBase}/api/v1/physician/toc/approve`,
      requestPayload
    );
    return response.data;
  }
);

export const getPacTypesAsync = createAsyncThunk(
  "toc/getPacTypesAsync",
  async () => {
    const response = await axiosPrivate.get(
      `${getConfig().taskManagementBase}/api/v1/physician/toc/pactypes`,
      {}
    );
    return response.data;
  }
);

export const getAchFacilities = createAsyncThunk(
  "toc-management/getAchFacilities",
  async ({ surgeonId, clientId }: { surgeonId: string; clientId: number }) => {
    const result = await axiosPrivate.get(
      `${
        getConfig().taskManagementBase
      }/api/v1/physician/toc/ach-facilities?surgeonId=${surgeonId}&clientId=${clientId}`
    );
    return result.data.map(
      (el: any) =>
        ({
          id: el.id,
          preferredProvider: el.preferredProvider,
          providerName: el.providerName,
          providerAddress1: el.providerAddress1,
          providerCity: el.providerCity,
          providerState: el.providerState,
          providerZip: el.providerZip,
          distance: "",
        } as IFacility)
    );
  }
);

export const getEpisodePatientDetailsAsync = createAsyncThunk(
  "toc/getEpisodePatientDetailsAsync",
  async (requestPayload: { intakeId: string }) => {
    const response = await axiosPrivate.get(
      `${getConfig().taskManagementBase}/api/v1/physician/toc/episode-details/${
        requestPayload.intakeId
      }`
    );
    if (response?.data?.data) {
      const {
        patient,
        dob = null,
        procedureName,
        surgeryDate = null,
        locationName = null,
        careManagerid = "",
        patientId = -1,
        patientUserId = null,
        gender,
      } = response.data.data;
      const correctDob = dob ? new Date(dob) : null;
      const newAge = correctDob ? getAgeFromDate(new Date(correctDob)) : -1;

      return {
        id: patientId,
        name: patient,
        age: newAge,
        procedureDescription:
          procedureName && !isEmptyString(procedureName) ? procedureName : null,
        surgeryDate: surgeryDate
          ? moment(surgeryDate, "YYYY-MM-DD").format("MM/DD/YYYY")
          : null,
        currentLocation:
          locationName && !isEmptyString(locationName) ? locationName : null,
        dob: correctDob,
        admitDate: null,
        careManagerId: careManagerid,
        userId: patientUserId,
        gender: gender ?? "",
      } as IEpisodePatientDetails;
    }
    return null;
  }
);

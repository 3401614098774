import { Link } from "react-router-dom";
import {
  isEmptyString,
  removeAllSpecialCharactersButNotSpace,
} from "shared/methods/utilityFunctions";
import "./styles.scss";

const Breadcrumb = ({
  pathname,
  extraInfo,
  isPatientPage,
  redirectTo,
}: {
  pathname: string;
  extraInfo?: string;
  isPatientPage?: boolean;
  redirectTo?: { path: string; name: string };
}) => {
  const individualLinks = pathname.split("/");

  const getHeader = () => {
    if (redirectTo) {
      return (
        <li className={`breadcrumb-item ${"active"}`} aria-current="page">
          <Link to={`/${redirectTo.path}`}>{redirectTo.name}</Link>
        </li>
      );
    } else {
      return individualLinks.map((name, index) => {
        if (name !== "") {
          return (
            <li
              key={name}
              className={`breadcrumb-item ${
                index === individualLinks.length - 1 ? "inactive" : "active"
              }`}
              aria-current="page"
            >
              <Link to={`/${name}`}>
                {index > 1 ? (
                  <div className="name">
                    {isPatientPage
                      ? name
                      : removeAllSpecialCharactersButNotSpace(
                          name
                        ).toUpperCase()}
                  </div>
                ) : (
                  removeAllSpecialCharactersButNotSpace(name).toUpperCase()
                )}
              </Link>
            </li>
          );
        }
      });
    }
  };
  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        {getHeader()}
        {extraInfo && !isEmptyString(extraInfo) && (
          <div className="extra-breadcrumb-info">
            {redirectTo ? "| " : ""}
            {extraInfo}
          </div>
        )}
      </ul>
    </nav>
  );
};

export default Breadcrumb;

export enum SortingOrderType {
  DEFAULT = "Default",
  ASC = "Asc",
  DESC = "Desc",
}

export enum MessagesSubjectType {
  GENERAL = "General",
}
export enum MessageOpenFrom {
  COMPOSE = "compose",
  EMPTY = "",
}

export enum CarePlanServices {
  ACUTE = "Acute",
}

import moment from "moment";
import { METERS_PER_MILE } from "shared/constant/constants";

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validateNumber = (value: string) => {
  const regex = /^[0-9]+$/;
  return regex.test(value);
};

export const removeAllSpecialCharacters = (str: string) => {
  return str.replace(/[^a-zA-Z_-]/g, "");
};

export const removeAllSpecialCharactersButNotSpace = (str: string) => {
  return str.replace(/[^a-zA-Z ]/g, " ");
};

export const isEmptyString = (str: string) => {
  return /^\s*$/.test(str);
};

export const isEmptyArray = (arr: Array<any>) => {
  return arr.length === 0;
};

export const isValidPhoneNumber = (value: string) => {
  const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return regex.test(value);
};

export const setCookie = (name: any, value: any, days = 365) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name: any) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name: any) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const validateMBI = (mbi: string) => {
  const regex =
    /^[1-9]{1}[^SLOIBZsloibz|^0-9|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[^SLOIBZsloibz|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[0-9]{1}?[^SLOIBZsloibz|^0-9|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[^SLOIBZsloibz|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[0-9]{1}?[^SLOIBZsloibz|^0-9|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[^SLOIBZsloibz|^0-9|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[0-9]{1}[0-9]{1}$/i;
  return regex.test(mbi);
};

export const getUTCDate = (date: Date) => {
  return `${moment(date).toISOString(true).substring(0, 23)}Z`;
};

export const getEndDate = (date: Date) => {
  return `${moment(date.setHours(23, 59, 59))
    .toISOString(true)
    .substring(0, 23)}Z`;
};

export const getGender = (gender: string) => {
  if (gender === "M") {
    return "Male";
  } else if (gender === "F") {
    return "Female";
  } else {
    return gender;
  }
};

export const formatBytes = (bytes: number | undefined, decimals = 2) => {
  if (bytes === 0 || typeof bytes === "undefined") return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const hasSpecialCharacter = (value: string) => {
  const regex = /[^A-Za-z0-9]/;
  return regex.test(value);
};

export const dispatchCustomEvent = (eventName: string, data: any = null) => {
  const customEvent = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(customEvent);
};
export const isFileTypeImage = (typeOfTheFile: string) =>
  typeOfTheFile === "image" ||
  typeOfTheFile === "image/png" ||
  typeOfTheFile === "image/jpg" ||
  typeOfTheFile === "image/jpeg";

export const isFileDocument = (typeOfTheFile: string) =>
  typeOfTheFile === "application/pdf" ||
  typeOfTheFile === "pdf" ||
  typeOfTheFile === "application/msword" ||
  typeOfTheFile ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
  typeOfTheFile === "word";

export const getPatientFullName = (item: {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}): string => {
  const { firstName, middleName, lastName } = item;
  const arr = [];
  if (firstName) {
    arr.push(firstName);
  }
  if (middleName) {
    arr.push(middleName);
  }
  if (lastName) {
    arr.push(lastName);
  }
  if (arr.length === 0) {
    return "-";
  }
  return arr.join(" ");
};

export const getDateInMMDDYYYYFormat = (date: any | null) => {
  if (date === null || isNaN(date)) {
    return "";
  } else {
    return moment(date).format("MM/DD/YYYY");
  }
};

export const getAgeFromDate = (birthday: any) => {
  const ageDifMs = Date.now() - birthday;
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};


export const convertMetersToMiles = (lengthInMeters: number) => {
  const dist = (lengthInMeters / METERS_PER_MILE).toFixed(1);
  const [wholeVal, fracVal] = dist.toString().split(".");
  if (fracVal === "0") {
    return [wholeVal, "miles"].join(" ");
  }
  return [dist, "miles"].join(" ");
};

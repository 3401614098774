import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { getCookie } from "shared/methods/utilityFunctions";
import MessageImage from "shared/assets/images/messages.png";
import MyPatientImage from "shared/assets/images/my-patients.png";
import PendingTocsImage from "shared/assets/images/pending-tocs.png";
import DashboardButton from "components/DashboardButton/dashboard-button.component";
import "./dashboard.styles.scss";
import { useHistory } from "react-router";

const Dashboard = () => {
  const loggedInUser = getCookie("loggedInUser");
  const [user, setUser] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    if (loggedInUser) {
      const parsedUser = JSON.parse(loggedInUser);
      setUser(`${parsedUser.firstName} ${parsedUser.lastName}`);
    }
  }, [loggedInUser]);

  return (
    <div id="dashboard">
      <Header className="dashboard">{`Hi, ${user}`}</Header>
      <div className="dashboard-buttons">
        <DashboardButton
          onClick={() => history.push("/toc")}
          text="Care Plans"
          image={PendingTocsImage}
        />
        <DashboardButton
          onClick={() => history.push("/patient-episodes")}
          text="Patient Episodes"
          image={MyPatientImage}
        />
        <DashboardButton
          onClick={() => {history.push("/messages")}}
          text="Messages"
          image={MessageImage}
        />
      </div>
    </div>
  );
};

export default Dashboard;

import { useEffect } from "react";
import Breadcrumb from "components/Breadcrumb";
import Header from "components/Header";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { getGender } from "shared/methods/utilityFunctions";
import {
  fetchTransitionOfCareDetailsAsync,
  getAchFacilities,
  getAllTocFacilitiesAsync,
  getEpisodePatientDetailsAsync,
  getPacTypesAsync,
} from "state/features/toc/toc.action";
import { getTocs, resetTocs } from "state/features/toc/toc.slice";
import "./toc-detail.styles.scss";
import EmptyState from "components/empty-state/empty-state.component";
import { toast } from "react-toastify";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import { TOC_LISTING_HEADERS } from "./constants/index.constant";
import TocRevision from "./toc-revision/toc-revision";
import AddServiceModal from "components/add-service-modal/add-service-modal.container";
import { getCommon } from "state/features/common/common.slice";

const TocDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { intakeId } = useParams<{ intakeId: string }>();
  const tocState = useSelector(getTocs);
  const { isOpenAddServiceModal } = useSelector(getCommon);
  const {
    isTocDetailsLoading,
    tocDetails,
    patientDetails,
    isEpisodePatientDetailsLoading,
  } = tocState;

  const isTocDetailsPresent =
    !isTocDetailsLoading &&
    !isEpisodePatientDetailsLoading &&
    tocState &&
    tocState.tocDetails &&
    tocState.tocDetails.length > 0 &&
    patientDetails;

  useEffect(() => {
    if (intakeId) {
      dispatch(fetchTransitionOfCareDetailsAsync({ intakeId }));
      dispatch(getEpisodePatientDetailsAsync({ intakeId }));
      dispatch(getAllTocFacilitiesAsync(intakeId));
    }
  }, [dispatch, intakeId]);

  useEffect(() => {
    dispatch(getPacTypesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (
      tocDetails.length > 0 &&
      tocDetails[0]?.surgeonId &&
      tocDetails[0]?.clientId
    ) {
      dispatch(
        getAchFacilities({
          surgeonId: tocDetails[0].surgeonId.toString(),
          clientId: tocDetails[0]?.clientId,
        })
      );
    }
  }, [dispatch, tocDetails]);

  useEffect(() => {
    return () => {
      dispatch(resetTocs());
    };
  }, [dispatch]);

  useEffect(() => {
    if (tocState && tocState.isValid && !tocState.isError) {
      toast.success("Care Plan approved successfully.", {
        toastId: "care-plan-approval",
      });
      history.replace("/toc");
    }
  }, [tocState]);

  const getTocsList = () => {
    return tocDetails.map((toc) => <TocRevision toc={toc} key={toc.id} />);
  };
  if (isTocDetailsPresent) {
    return (
      <>
        {isOpenAddServiceModal.isOpen && <AddServiceModal />}
        <div id="toc-detail">
          <div className="toc-detail-presentation">
            <div className="bread-crumb">
              <Breadcrumb
                pathname={location.pathname}
                extraInfo={patientDetails.name}
                redirectTo={{ path: "toc", name: "Care Plan" }}
              />
            </div>
            <Header className="toc-detail">
              <div className="heading">{patientDetails.name}</div>
              <div className="age-gender">
                {`${getGender(patientDetails.gender)}, ${
                  patientDetails.age ? patientDetails.age + " years" : " "
                }`}
              </div>
            </Header>
            <div className="more-details">
              <div className="info">
                <div className="row">Navigator Assigned</div>
                <div className="val">
                  {tocState.tocDetails[0].careManagerName
                    ? tocState.tocDetails[0].careManagerName
                    : "-"}
                </div>
              </div>
              <div className="info">
                <div className="row">Current Location</div>
                <div className="val">
                  {patientDetails.currentLocation
                    ? patientDetails.currentLocation
                    : "-"}
                </div>
              </div>
              <div className="info">
                <div className="row">Procedure</div>
                <div className="val">
                  {patientDetails.procedureDescription
                    ? patientDetails.procedureDescription
                    : "-"}
                </div>
              </div>
              <div className="info">
                <div className="row">Date of Procedure</div>
                <div className="val">
                  {patientDetails.surgeryDate
                    ? moment(patientDetails.surgeryDate).format("MM/DD/YYYY")
                    : "-"}
                </div>
              </div>
            </div>
            <div className="revisons">
              <div className="heading"> Care Plans</div>
              <div className="revision-table-container">
                <div className="revision-table">
                  <div className="revision-table-header">
                    {TOC_LISTING_HEADERS.map(({ name }) => {
                      return (
                        <div className="revision-table-header-cell" key={name}>
                          <div className="revision-table-header-content">
                            <div className="name">{name}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="revision-table-body">
                    {isTocDetailsLoading ? (
                      <EmptyState type={EmptyStateType.LOADING} />
                    ) : (
                      getTocsList()
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (
    tocState.isTocDetailsLoading ||
    tocState.isEpisodePatientDetailsLoading
  ) {
    return (
      <div id="toc-detail">
        <EmptyState type={EmptyStateType.LOADING} />
      </div>
    );
  } else {
    return (
      <div id="toc-detail">
        <EmptyState type={EmptyStateType.NO_TOC_DETAILS} />
      </div>
    );
  }
};

export default TocDetail;


import { TOCDetailType } from "pages/toc-detail/toc-detail-types";
export interface Item {
  providerId: string;
  admission?: string;
  daysType: string;
  longName: string;
  pacTypeId: number;
  quantity: number;
  sequence?: number;
  shortName: string;
  standardCarePlanId: number;
  admissionDate?: string;
  id: string;
  isPrefferedProvider?: boolean;
  pacTypeName: string;
  targetLos: string;
}

export interface DragItem {
  index: number;
  id: string;
}
export enum IntakeTypeEnum {
  NEW = "new",
  OLD = "old",
}
export interface RowProps {
  item: Item;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  toc: TOCDetailType;
  intakeType?: IntakeTypeEnum;
}

export interface SortableListPresentationProps {
  list: CarePlanData[];
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  toc: TOCDetailType;
  intakeType?: IntakeTypeEnum;
}

export interface SelectedCarePlan {
  name: string;
  key: string;
}
export interface CarePlanData {
  admission?: string;
  daysType: string;
  longName: string;
  pacTypeId: number;
  quantity: number;
  sequence?: number;
  shortName: string;
  standardCarePlanId: number;
  providerId: string;
  isPrefferedProvider: boolean;
  id: string;
  pacTypeName: string;
  targetLos: string;
}

export interface SortableListProps {
  toc: TOCDetailType;
  intakeType?: IntakeTypeEnum;
}

import React from "react";
import { CheckboxProps } from "./props";
import "./checkbox.styles.scss";

const Checkbox = ({
  className,
  value,
  name,
  onChange,
  onClick,
  id,
  isChecked,
  isDisabled,
  labelClassname,
}: CheckboxProps) => {
  return (
    <label className={`checkbox-label ${labelClassname ?? ""}`} htmlFor={id}>
      <input
        id={id}
        type="checkbox"
        className={`checkbox ${className}`}
        name={name}
        onChange={onChange}
        onClick={onClick}
        checked={isChecked}
        disabled={isDisabled}
      />
      {value}
    </label>
  );
};

export default Checkbox;

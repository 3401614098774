import type { Identifier, XYCoord } from "dnd-core";
import { useDispatch, useSelector } from "react-redux";
import { DragItem, IntakeTypeEnum, RowProps } from "./sortable-list.props";
import { useRef } from "react";
import {
  deletePacTypeItem,
  getTocs,
  setEditTocDetails,
  setTocEditForm,
} from "state/features/toc/toc.slice";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./item-types";
import { CarePlanServices } from "shared/types/enum";
import {
  PAC_TYPE,
  TocStatusEnum,
} from "pages/toc-detail/constants/index.constant";
import Icon from "components/Icon";
import { setIsOpenAddServiceModal } from "state/features/common/common.slice";
import "./sortable-row.styles.scss";

const SortableRow = (props: RowProps) => {
  const dropRef = useRef<HTMLDivElement | null>(null);
  const dragRef = useRef<HTMLDivElement | null>(null);
  const { item, index, moveCard, intakeType, toc } = props;
  const dispatch = useDispatch();
  const { carePlanData, allFacilities, facilities, tocEditForm } =
    useSelector(getTocs);
  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.listRow,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(i, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = i.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      i.index = hoverIndex;
      dispatch(setTocEditForm({ ...tocEditForm, isTocItemsFormDirty: true }));
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.listRow,
    previewOptions: {
      captureDraggingState: true,
    },
    options: {
      dropEffect: "move",
    },
    item: () => {
      return { id: item.id, index };
    },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  if (index !== 0) {
    preview(drop(dropRef));
    drag(dragRef);
  }

  const getProvider = () => {
    if (item.providerId) {
      return allFacilities.find(
        (facility) => facility.id.toString() === item.providerId.toString()
      )?.providerName;
    }
  };

  const isFacilityPriviliged = function () {
    if (item.longName === CarePlanServices.ACUTE) {
      const isPriviliged = facilities.find(
        (facility) => item.providerId.toString() === facility.id.toString()
      )?.isPrivileged;
      return isPriviliged === undefined ? true : false;
    }

    return false;
  };

  const getIsPreferredProvider = () => {
    if (item.longName === CarePlanServices.ACUTE) {
      return facilities.find(
        (facility) => item.providerId.toString() === facility.id.toString()
      )?.preferredProvider;
    } else {
      return allFacilities.find(
        (facility) => item.providerId.toString() === facility.id.toString()
      )?.preferredProvider;
    }
  };

  const getDistanceIfPresent = () => {
    let distance = null;
    if (item.longName === CarePlanServices.ACUTE) {
      distance = facilities.find(
        (facility) => item.providerId.toString() === facility.id.toString()
      )?.distance;
    } else {
      distance = allFacilities.find(
        (facility) => item.providerId.toString() === facility.id.toString()
      )?.distance;
    }
    return distance !== undefined ? distance.split("miles")[0] : null;
  };

  const getEditButton = () => {
    if (!tocEditForm.isHomeWithNoServicesEnabled || index === 0) {
      return true;
    }
    if (toc.status === TocStatusEnum.PENDING) {
      return true;
    }
    if (
      intakeType === IntakeTypeEnum.OLD &&
      carePlanData.find(
        (carePlan) => carePlan.longName === PAC_TYPE.ACUTE
      ) !== undefined
    ) {
      return true;
    }

    return false;
  };

  const getDeleteButton = () => {
    return item.longName !== PAC_TYPE.ACUTE;
  };

  return (
    <div ref={dropRef} className={`${isDragging ? "preview" : ""}`}>
      <div
        ref={dragRef}
        className={`sortable-row ${index === 0 ? "cursor-not-allowed" : ""}  ${
          isDragging ? "animate" : "opacity-1"
        } ${
          tocEditForm.isHomeWithNoServicesEnabled &&
          item.longName !== PAC_TYPE.ACUTE
            ? "opacity-04"
            : ""
        }`}
      >
        <div className={`row-content`}>
          <div className="drag-icon">
            <div>
              <Icon icon="drag-image" size={12} />
            </div>
          </div>
          <div className="services">{item.longName}</div>
          <div className="provider">
            <div className="provider-name">{getProvider()}</div>
            {getProvider() && (
              <div className="pref-priv-images">
                {getIsPreferredProvider() ? (
                  <div>
                    <Icon icon="preferred" size={16} />
                  </div>
                ) : (
                  <div>
                    <Icon icon="not-preferred" size={16} />
                  </div>
                )}
                {item.longName === CarePlanServices.ACUTE &&
                  isFacilityPriviliged() && (
                    <div>
                      <Icon icon="privileged" size={16} />
                    </div>
                  )}
                {item.longName === CarePlanServices.ACUTE &&
                  !isFacilityPriviliged() && (
                    <div>
                      <Icon icon="not-privileged" size={16} />
                    </div>
                  )}
                {getDistanceIfPresent() && (
                  <div className="distance-container">
                    Dist:{" "}
                    <span className="distance">{getDistanceIfPresent()}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="los">
            {item.quantity} {item.daysType ? item.daysType.toLowerCase() : ""}
          </div>
          {getEditButton() ? (
            <div
              className="edit-button"
              data-tip
              data-for="edit-toc"
              onClick={() => {
                dispatch(setEditTocDetails(item));
                dispatch(
                  setIsOpenAddServiceModal({ isOpen: true, isEdit: true })
                );
              }}
              title="Edit"
            >
              <Icon icon="edit" size={16} />
              {/* <CustomToolTip id="edit-toc" text="Edit" place="top" /> */}
            </div>
          ) : (
            <div></div>
          )}
          {getDeleteButton() ? (
            <div
              className="edit-button"
              data-tip
              data-for="delete-toc"
              onClick={() => {
                dispatch(deletePacTypeItem(item.longName));
              }}
              title="Delete"
            >
              <Icon icon="poc-delete-icon" size={16} />
              {/* <CustomToolTip id="delete-toc" text="Delete" place="top" /> */}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SortableRow;

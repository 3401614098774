import Icon from "components/Icon";
import React, { useState, useEffect } from "react";
import "./styles.scss";

type CustomDropDownProps = {
  placeholder?: string;
  idFieldName: string;
  handleToastDismiss?: () => void;
  dropDownMenuItems: Record<string, unknown>[];
  handleValueChanges: (value: { name: string }) => void;
  defaultValue?: { name: string; key?: string };
  itemClassName?: string;
  optionClassName?: string;
  dropDownContainerClass?: string;
  dropDownBoxClass: string;
  selectionClass: string;
  isDisabled?: boolean;
  showError?: boolean;
  errorMessage?: string;
};

const CustomDropDown = ({
  placeholder,
  idFieldName,
  handleToastDismiss,
  dropDownMenuItems,
  handleValueChanges,
  defaultValue,
  itemClassName,
  optionClassName,
  dropDownContainerClass,
  dropDownBoxClass,
  selectionClass,
  isDisabled,
  showError,
  errorMessage,
}: CustomDropDownProps) => {
  const [activeOption, setActiveOption] = useState<any>({
    name: "",
  });
  const [showOptionDropdown, setShowOptionDropdown] = useState(false);
  const [dropDownItems, setDropDownItems] = useState<any>();
  useEffect(() => {
    if (defaultValue /*&& !isEmptyString(defaultValue.name)*/) {
      setActiveOption(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setDropDownItems(dropDownMenuItems);
  }, [dropDownMenuItems]);

  const handleDropdownChange = (state: any) => {
    if (handleToastDismiss) handleToastDismiss();
    setShowOptionDropdown((prev) => {
      const arrowEl = document.getElementById("select-box-bucket-down-arrow");
      if (prev) {
        arrowEl?.classList.remove("select-box-down-arrow-rotate");
        arrowEl?.classList.add("select-box-down-arrow-unrotate");
        return !prev;
      } else {
        if (state) {
          arrowEl?.classList.remove("select-box-down-arrow-unrotate");
          arrowEl?.classList.add("select-box-down-arrow-rotate");
          return !prev;
        } else return false;
      }
    });
  };

  const handleSelect = (selectedOption: any) => {
    setActiveOption(selectedOption);
    handleValueChanges(selectedOption);
  };

  const handleOptionChange = (option: any) => {
    if (handleSelect) handleSelect(option);
    handleDropdownChange(false);
  };

  const onBlur = (e: any) => {
    const isClickedOnDropdownOptions = e.relatedTarget
      ? Object.values(e.relatedTarget.classList).includes("option-box-wrapper")
      : false;
    if (!isClickedOnDropdownOptions) {
      handleDropdownChange(false);
    }
  };

  return (
    <div
      className={`dropdown-container ${
        dropDownContainerClass ? dropDownContainerClass : ""
      }`}
      tabIndex={0}
      onBlur={onBlur}
    >
      <span
        className={`select-span ${selectionClass} ${
          isDisabled ? "no-pointer-events dropdown-container-disabled" : ""
        } ${showError ? "red-border" : ""}`}
        onClick={(e) => {
          if (!isDisabled) {
            e.preventDefault();
            e.stopPropagation();
            handleDropdownChange(true);
          }
        }}
      >
        <div className="name-container">
          <p
            className={`selected-value ${
              activeOption?.name ? "" : "placeholder"
            }`}
          >
            {activeOption?.name ? activeOption?.name : placeholder}
          </p>
        </div>
        <div className="icon-container">
          <Icon icon="down-arrow" className="select-box-down-arrow" size={11} />
        </div>
      </span>
      {!isDisabled && showError && (
        <div className="error-wrap">{errorMessage}</div>
      )}
      {showOptionDropdown && (
        <div className={`option-box-wrapper ${dropDownBoxClass}`}>
          <ul>
            {dropDownItems?.map((option: any, key: number) => (
              <li
                key={key}
                className={`${itemClassName ? itemClassName : ""}
										${
                      activeOption &&
                      activeOption[idFieldName] === option[idFieldName]
                        ? "active-option"
                        : ""
                    }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOptionChange(option);
                }}
              >
                {activeOption &&
                activeOption[idFieldName] === option[idFieldName] ? (
                  <p
                    className={`option selected ${
                      optionClassName ? optionClassName : ""
                    }`}
                  >
                    {option.name}
                  </p>
                ) : (
                  <p
                    className={`option ${
                      optionClassName ? optionClassName : ""
                    }`}
                  >
                    {option.name}
                  </p>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;
